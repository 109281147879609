











import {Vue, Component} from "vue-property-decorator";
import {WithdrawCash} from "@/request/after-sale/WithdrawCash";

@Component({
    name: 'after_sale_withdrawCash'
})
export default class after_sale_withdrawCash extends Vue {
    modal: any = WithdrawCash;
    search: Array<any> = [
        {
            type: 'Input',
            label: '电话',
            key: 'phone',
            width: '140px',
            clearable: true,
            placeholder: '请填写电话'
        },
        {
            type: 'DatePicker',
            pickerType: 'daterange',
            format: 'yyyy-MM-dd',
            label: '提现时间',
            width: '210px',
            placeholder: '请选择注册时间'
        }
    ];
    columns: Array<any> = [
        /*{
            title: '订单号',
            key: 'orderId',
            minWidth: 160
        },*/
        {
            title: '昵称',
            key: 'nickName',
            minWidth: 120
        },
        {
            title: '电话',
            key: 'phone',
            minWidth: 120
        },
        {
            title: '提现金额',
            key: 'withdrawFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.withdrawFee))
        },
        {
            title: '剩余金额',
            key: 'curLastFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.curLastFee))
        },
        {
            title: '提现时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    created() {

    }
}
